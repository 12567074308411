import {
  SET_FIRSTNAME,
  SET_LASTNAME,
  SET_DATE,
  SET_INSURANCE_PAYER,
  SET_INSURANCE_MEMBER_ID,
  SET_INSURANCE_PAYER_ID,
  SET_LOADING,
  SET_INSURANCE_SUCCESS,
  SET_CLEAR_INSURANCE,
  SET_VALID_INSURANCE_OBJECT,
  SET_INSURANCE_ERROR,
  SET_SELECT_NO_INSURANCE_OPTION,
  SET_CLEAR_INSURANCE_STORE,
  SET_COVERAGE_ID,
  SET_FLAGGED_DATA,
  SET_COVERAGE_TYPE,
  SET_IN_NETWORK,
  SET_EXPLANATION,
  CLEAR_PAYER,
} from '../actionTypes/InsuranceActionTypes';

export const initialState = {
  firstName: '',
  lastName: '',
  date: '',
  payer: '',
  payerId: '',
  memberId: '',
  coverageID: null,
  inNetwork: '',
  hasValidInsuranceObject: false,
  insuranceSuccess: false,
  selectNoInsuranceOption: false,
  flaggedData: {
    flaggedCoverageData: false,
  },
  loading: false,
  error: null,
  coverageType: '',
  explanation: {
    long: null,
    short: null,
  },
};

function FloatingInsuranceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FIRSTNAME:
      return { ...state, firstName: action.value };
    case SET_LASTNAME:
      return { ...state, lastName: action.value };
    case SET_LOADING:
      return { ...state, loading: action.value };
    case SET_INSURANCE_ERROR:
      return { ...state, error: action.value };
    case SET_DATE:
      return { ...state, date: action.value };
    case SET_INSURANCE_PAYER:
      return { ...state, payer: action.value };
    case SET_INSURANCE_PAYER_ID:
      return { ...state, payerId: action.value };
    case SET_INSURANCE_MEMBER_ID:
      return { ...state, memberId: action.value };
    case SET_INSURANCE_SUCCESS:
      return { ...state, insuranceSuccess: action.value };
    case SET_VALID_INSURANCE_OBJECT:
      return { ...state, hasValidInsuranceObject: action.value };
    case SET_COVERAGE_ID:
      return { ...state, coverageID: action.value };
    case SET_FLAGGED_DATA:
      return { ...state, flaggedData: action.value };
    case SET_SELECT_NO_INSURANCE_OPTION:
      return { ...state, selectNoInsuranceOption: action.value };
    case SET_CLEAR_INSURANCE_STORE:
      return { ...undefined };
    case SET_COVERAGE_TYPE:
      return { ...state, coverageType: action.value };
    case SET_IN_NETWORK:
      return { ...state, inNetwork: action.value };
    case SET_CLEAR_INSURANCE:
      return {
        ...state,
        payer: '',
        payerId: '',
        memberId: '',
        coverageType: '',
        hasValidInsuranceObject: false,
        flaggedData: null,
        insuranceSuccess: false,
        error: null,
        loading: false,
      };
    case SET_EXPLANATION:
      return { ...state, explanation: action.value };

    case CLEAR_PAYER:
      return {
        ...state,
        payerId: '',
        payerName: '',
        payer: '',
      };
    default:
      return state;
  }
}

export default FloatingInsuranceReducer;
