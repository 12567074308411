/* eslint-disable */
import {
  IS_USER_LOGGED_IN,
  IS_USER_NOT_LOGGED_IN,
  SET_EMAIL,
  SET_PAYMENT,
  SET_PROMO_CODE_DATA,
  SET_SUBSCRIPTIONS,
  SET_THERAPY_CONSENT_STATE,
  SET_USER_EDITABLE_DATA,
  SET_REPEAT_PATIENT,
  UPDATE_SUBSCRIPTIONS,
  USER_FULL_LOGOUT,
  USER_LOG_IN_ERROR,
  USER_LOG_OUT,
  SET_TOS_OPTIN,
} from 'src/js/actionTypes/UserActionType';
import { SET_SOURCE } from 'src/js/actionTypes/SourceActionType';
import {
  setClearInsurance,
  setCoverageType,
  setExistingInsurance,
  setFlaggedData,
} from 'src/js/actions/InsuranceActions';
import {
  setHomeAddress,
  setPharmacyAddress,
} from 'src/js/actions/PharmacyActions';
import {
  apiUrl,
  appUrl,
  accdXUrl,
  PlushcareWebAPI,
  updateLocation
} from 'src/js/utils';
import { isServer } from 'src/js/utils/server';
import { useAppVariant } from 'utils/hooks/useAppVariant';

const API_CLIENT_ID = process.env.NEXT_PUBLIC_API_CLIENT_ID
const API_CLIENT_SECRET = process.env.NEXT_PUBLIC_API_CLIENT_SECRET
const ACCOLADE_X_BASE_URL = process.env.NEXT_PUBLIC_ACCOLADEX_URL;

export const loggedIn = data => ({ type: IS_USER_LOGGED_IN, payload: data })
export const notLoggedIn = () => ({ type: IS_USER_NOT_LOGGED_IN })
export const loginError = errorMessage => ({
  type: USER_LOG_IN_ERROR,
  payload: { errorMessage },
})
export const clearUserData = () => ({ type: USER_LOG_OUT })
export const setUserEditableData = (firstName, lastName, dob) => ({
  type: SET_USER_EDITABLE_DATA, payload: { firstName, lastName, dob },
})

export function updateSubscription (subscription) {
  return {
    type: UPDATE_SUBSCRIPTIONS,
    payload: subscription,
  }
}

export function setSubscriptions (subscriptions) {
  return {
    type: SET_SUBSCRIPTIONS,
    payload: subscriptions,
  }
}

export const setRepeatPatient = () => dispatch => (
    dispatch({ type: SET_REPEAT_PATIENT, payload: { repeat_patient: true } })
);

export const setTosOptin = (tosOptin) => (
  {
    type: SET_TOS_OPTIN,
    payload: tosOptin,
  }
)

export function setPayment (payment) {
  return {
    type: SET_PAYMENT,
    payload: payment,
  }
}

export const setSource = source => ({ type: SET_SOURCE, payload: source })

export const fullLogout = () => ({ type: USER_FULL_LOGOUT })

export const setPromoCodeData = payload => ({
  type: SET_PROMO_CODE_DATA,
  payload,
})

export const fetchPromoCodeData = promoCode => async (dispatch) => {
  const { data } = await PlushcareWebAPI.get(apiUrl.promotions.campaign,
    { code: promoCode }, true)
  dispatch(setPromoCodeData({ promoCode, ...data }))
}

export const logout = () => async (dispatch) => {
  const { isAccoladeX } = useAppVariant();
  let logoutUrl = '/';

  const postData = {
    client_id: API_CLIENT_ID,
    client_secret: API_CLIENT_SECRET,
  }

  if (isAccoladeX) {
    try {
      const { data } = await PlushcareWebAPI.apiGetAccoladeX(accdXUrl.nextauth.session);
      if (data && data.logoutUrl) {
        logoutUrl = data.logoutUrl;
      }
      const { data: { csrfToken } } = await PlushcareWebAPI.apiGetAccoladeX(accdXUrl.nextauth.csrf);
      await fetch(
        accdXUrl.nextauth.logout,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            callbackUrl: ACCOLADE_X_BASE_URL,
            csrfToken: csrfToken,
            json: true,
          }),
          method: 'POST',
        }
      );
    } catch(e) {
      console.error(e);
    }
  }

  try {
    await PlushcareWebAPI.post(apiUrl.patients.logout, postData, false, true);
  } catch(e) {
    console.error(e);
  } finally {
    dispatch(fullLogout());
    isAccoladeX ? window.location = logoutUrl : updateLocation(appUrl.login);
  }
}

// TODO:: deprecate up all of this -> need to simplify user handling
export const storeUser = user => (dispatch) => {
  if (user) {
    const {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      user_id,
      id,
      is_logged_in: isLoggedIn,
      type,
      impersonator,
      subscription_memberships: subscriptions,
      source,
      password_origin: passwordOrigin,
      phonenumber_origin: phoneNumberOrigin,
      tos_optin: tosOptin,
      user_feature_flags: userFeatureFlags,
      userprofile,
    } = user;

    const address = userprofile?.address;
    const pharmacyAddress = userprofile?.ss_pharmacy;
    dispatch(setHomeAddress(address));
    dispatch(setPharmacyAddress(pharmacyAddress, true));

    dispatch(loggedIn({
      email,
      phoneNumber,
      firstName,
      lastName,
      id,
      type,
      isLoggedIn,
      impersonator: isServer ? '' : (window.sessionStorage.getItem('PLUSHCARE_WEB_IMPERSONATOR') || impersonator),
      subscriptions,
      passwordOrigin,
      phoneNumberOrigin,
      userFeatureFlags,
      userprofile
    }));
    const coverage = (userprofile?.coverage.length) ? userprofile.coverage[0] : null;
    if (coverage && !coverage.is_expired) {
      dispatch(setExistingInsurance(coverage));
      dispatch(setFlaggedData({
        flaggedCoverageData: false,
      }))
    } else if (coverage?.is_expired) {
      dispatch(setClearInsurance());
    }
    if (userprofile?.group) {
      dispatch(setCoverageType('tpa'));
    }

    const {
        cc_last_four: cardLastFour,
        cc_brand: cardBrand,
        stripe_customer_id: stripeToken,
        num_invoice_errors: numInvoiceErrors,
    } = (userprofile) || {};
    if (cardLastFour) {
      dispatch(setPayment({
        cardLastFour,
        cardBrand,
        stripeToken,
        numInvoiceErrors,
      }));
    }
    if (subscriptions && subscriptions.length) {
      setSubscriptions(subscriptions);
    }
    dispatch(setSource(source));
  }
};

export const isUserLoggedIn = () => async (dispatch) => {
  try {
    const {
      user
    } = await PlushcareWebAPI.get(apiUrl.patients.retrieve, null, true);
    if (cache.get(apiUrl.payments.getPaymentInformationVer2)) {
      const data = cache.get(apiUrl.payments.getPaymentInformationVer2)
      user.cc_last_four = data.primary_is_wallet
        ? data.primary_payment_method?.wallet?.dynamic_last4
        : data.primary_payment_method?.last4
      user.cc_brand = data.primary_payment_method?.brand
    }
    dispatch(storeUser(user));
  } catch (error) {
    dispatch(notLoggedIn());
  }
};

export const setEmail = payload => ({
  type: SET_EMAIL,
  payload,
});

export const updateTherapyConsentState = (payload) => ({
  type: SET_THERAPY_CONSENT_STATE,
  payload,
});
