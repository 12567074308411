/* eslint-disable newline-per-chained-call,max-len */
import dayjs, { Dayjs, OpUnitType, UnitType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import istoday from 'dayjs/plugin/isToday';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import istomorrow from 'dayjs/plugin/isTomorrow';
import timezones from './timezones.json';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(istoday);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(istomorrow);

type FormatType = UnitType | OpUnitType;

// this is a format which JUST mimics "toISOString(true)" of momentjs. Example: 2021-05-07T13:23:36.162+02:00
const datetoISOStringKeepOffset = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const parseFromISOString = (datetime: string) => dayjs(datetime, datetoISOStringKeepOffset);

// please try to avoid using this as much as possible. If used somewhere it decentralizes possible handlers of dates
// and it becomes unobvious what date formats SPA works with and in which way
export const parseDate = (date: string) => dayjs(date);

export const parseDateTimeToDate = (date: string) => dayjs(date).toDate();

// 2021-05-07T13:23:36.162+02:00 => 2021-05-07
export const cutTimeFromDateString = (date: string) => date.split('T')[0];

// will return format 2021-05-23T00:00:00-07:00
export const getDateWithZeroedTime = (date: string) => dayjs(date).startOf('date').format();

export const parseAsISO = (date: string) => {
  const toMoment = dayjs(date);
  const isToday = toMoment.isSame(dayjs.utc(), 'day');
  return isToday
    ? dayjs.utc().local().second(0).millisecond(0).format(datetoISOStringKeepOffset)
    : toMoment.startOf('date').format(datetoISOStringKeepOffset);
};
// will return 2021-05-21T13:36:27Z
export const dateToIsoString = (date?: string | Date | Dayjs) => dayjs(date).format();

export const calculateNextAvailableDate = (date: string, nextDates: string[]) => nextDates.find(
  nextDate => dayjs(nextDate) > dayjs(date),
);

export const isToday = (date: string) => dayjs(date).isToday();

export const isTomorrow = (date: string) => dayjs(date).isTomorrow();

export const getTimezoneName = dayjs.tz.guess;

export const getDateInSpecificTz = (tz: string, date?: string) => dayjs(date).tz(tz);

export const todayInTimezone = () => dayjs.utc().local().format();

export const timeToUtcLocal = (date: string | Dayjs) => dayjs.utc(date).local().format();

export const durationInMiliseconds = (startTime: string, endTime: string) => dayjs(endTime).diff(startTime);

// returns positive number if compared with future, negative if with past
export const diffInHours = (to: string, from?: string) => dayjs.utc(to).diff(dayjs.utc(from), 'hour');

// returns positive number if compared with future, negative if with past
export const diffInDays = (to: string | number | Date | dayjs.Dayjs, from?: string | number | Date | dayjs.Dayjs) => dayjs.utc(to).diff(dayjs.utc(from), 'day');

// returns positive number if compared with future, negative if with past
export const diffInMinutes = (to: string, from?: string) => dayjs.utc(to).diff(dayjs.utc(from), 'minute');

// returns positive number if compared with future, negative if with past
export const diffInYears = (to?: string, from?: string): number => dayjs(to).diff(dayjs(from), 'year');

export const diffInYearsAgainstToday = (value?: Date | null) => (value ? diffInYears(value.toString()) * -1 : false);

// returns Date obj in format "Fri May 07 2021 00:00:00 GMT+0200 (Central European Summer Time)"
export const getDateWithoutTime = (date: string) => dayjs(date).hour(0).minute(0).second(0).millisecond(0).toDate();

export const getDateInUserTimezone = (date?: string) => dayjs(date).tz(dayjs.tz.guess());

export const getDateInUserTimezoneNoDateJS = (utcTime: string) => {
  const date = new Date(utcTime);
  const localString = date.toLocaleString('en-US', { timeZoneName: 'short' });
  const localTime = localString.split(', ')[1];
  const segmentedTime = localTime.split(':');
  const formattedTime = `
    ${segmentedTime[0]}:${segmentedTime[1]}
    ${segmentedTime[2].split(' ')[1]}
    ${segmentedTime[2].split(' ')[2]}
  `;
  return formattedTime;
};

export const getMonthName = (date: Date) => date.toLocaleString('en-US', { month: 'long' });

export const getCurrentYear = () => dayjs().format('YYYY');

export const getCurrentMonth = () => dayjs().format('MM');

export const getCurrentDay = () => dayjs().format('DD');

type FormattedDateTime =
  {
    date: string,
    time: string,
  }
export const getFormattedDateTime = (d: Date): FormattedDateTime => {
  const clearedInvalidTzOffset = clearDateFromInvalidTzOffset(d.toISOString());
  return {
    date: getDisplayedAppointmentDate(clearedInvalidTzOffset),
    time: getDateInUserTimezoneNoDateJS(d.toISOString()),
  };
};

export const getLongTimeZone = () => {
  /*
  Returns the user's long time zone string ie. Pacific Standard Time
  If it cannot get a timezone, it returns 'your local timezone'
   */
  const m = new Date().toString().match(/\(([A-Za-z\s].*)\)/);
  if (!m || m.length < 1) return 'your local timezone';
  return m[1];
};

export const getShortTimezone = (d: Date) => {
  /*
  Returns the user's short timezone ie. PST
   */
  return getFormattedDateTime(d).time.slice(-7).trim();
};

export const formatDateForAddToCalendar = (date: string, tz: string) => dayjs(date).tz(tz).format('YYYYMMDDTHHmmss');

// returns date in format "May 10th 2021"
export const getDisplayedAppointmentDate = (date: string) => getDateInUserTimezone(date).format('MMMM Do YYYY');

export const customFormatDate = (date: string | Dayjs, fmt = 'YYYY-MM-DD') => dayjs(date).utc().local().format(fmt);

export const getFormattedCoverageDOB = (date: string) => dayjs(date, 'YYYYMMDD').format('MMMM D, YYYY');

export const getFormattedProfileDOB = (date: string) => dayjs(date, 'MMDDYYYY').format('MMMM D, YYYY');

export const getFormattedProfileDOBRxBooking = (date: string) => dayjs(date, 'MM/DD/YYYY').format('MMDDYYYY');

export const getDisplayedAppointmentTime = (date: string) => `${getDateInUserTimezone(date).format('h:mm A')} ${getTimezoneShortName(date)}`;

// returns date in format "Nov 14"
export const getDisplayedAutoChangedDate = (date: string) => dayjs(date).format('MMM D');

// returns date in format "August 22"
export const getFullMonthAndDay = (date: string) => dayjs(date).format('MMMM D');

// returns date in format "May 14"
export const parseTimestampToCalendarDate = (timestamp: number) => dayjs.unix(timestamp).format('MMM D');

// returns date in format "Feb 11, 2021"
export const getSubscriptionEndFormattedDate = (date: string) => dayjs(date).format('MMM D, YYYY');

// returns date in format "April 2018"
export const getDocReviewFormattedDate = (date: string) => dayjs(date).format('MMMM YYYY');

// returns year in format "2023"
export const getYear = (date: string) => dayjs(date).format('YYYY');

export const getTimeLeftFormatted = (date: number | string) => dayjs(date).format('m:ss');

export const getTestResultFormattedDate = (date: string) => dayjs(date).format('MMMM DD, YYYY');

export const getFormattedVisitHistoryDate = (date: string, time: string) => {
  const dateObj = dayjs(`${date}T${time}`, 'YYYYMMDDTHH:mm:ss').tz(dayjs.tz.guess());
  const d = dateObj.format('MMMM DD, YYYY');
  const t = dateObj.format('h:mma');

  return `${d} at ${t}`;
};

// returns date in format "Saturday, Feb 1 "
export const getDrNextAvailableAppointmentDate = (utcDate: string) => getDateInUserTimezone(utcDate).format('dddd, MMM DD');

// returns Thursday, June 24 at 5:40pm CEST
export const formatDateWithTimezone = (utcDate: string) => {
  const date = getDateInUserTimezone(utcDate).format('dddd, MMMM DD');
  const time = getDateInUserTimezone(utcDate).format('h:mma');
  const zone = getTimezoneShortName(utcDate);

  return `${date} at ${time} ${zone}`;
};

export const formatRecurringDate = (utcDate: string) => {
  const date = getDateInUserTimezone(utcDate).format('dddd');
  const time = getDateInUserTimezone(utcDate).format('h:mma');
  const zone = getTimezoneShortName(utcDate);

  return `${date}s at ${time} ${zone}`;
};

export const getTimezoneShortName = (date: string, tz?: string) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) return '';

  const tzVal = tz || dayjs.tz.guess();
  const parsedTZShortName = parsedDate.tz(tzVal).format('z');
  // dayjs uses Intl for definition of time zone name. https://github.com/iamkun/dayjs/issues/1154
  // So if our user is in such time zone, we fallback to manually mapped time zones list
  const isGMTVersion = parsedTZShortName.includes('GMT');
  if (isGMTVersion) {
    const longTimezoneGuessed = dayjs().tz(tzVal).format('zzz');
    return timezones.find(zone => zone.value === longTimezoneGuessed)?.abbr || parsedTZShortName;
  }
  return parsedTZShortName;
};

export const getFormattedDateOfSelectedApt = (date: string) => dayjs(cutTimeFromDateString(date)).format('YYYY-MM-DD');

// if dob is pre-populated from user data it can be MMDDYYYY, if user provided it manually it's MM/DD/YYYY
export const fixAndFormatPrePopulatedDobField = (date: string) => dayjs(date, ['MMDDYYYY', 'MM/DD/YYYY']).format('YYYY-MM-DD');

export const getFormattedDateFromISOShort = (date: string) => dayjs(date, 'YYYY-MM-DD').format('MM/DD/YYYY');

// returns formatted endDate of canceled membership
export const getFormattedEndDate = (date: string) => ({
  formatted: dayjs(date).format('MM-DD-YYYY'),
  asCalendarDate: dayjs(date, 'MM-DD-YYYY').calendar(),
});

// returns formatted end_date of canceled subscription
export const getFormattedEnd_Date = (date: string) => dayjs(date, 'YYYY-MM-DD').calendar();

export const isDateValid = (date: any) => dayjs(date).isValid();

export const isDateObject = (date: any) => date instanceof Date && !Number.isNaN(date?.valueOf());

export const isDateInPast = (date: string) => dayjs().isAfter(date);

export const isDateInFuture = (date: string) => dayjs().isBefore(date);

export const isDateBefore = (dateA: string, dateB: string) => dayjs(dateA).isBefore(dayjs(dateB));

export const isDateAfter = (dateA: string, dateB: string) => dayjs(dateA).isAfter(dayjs(dateB));

export const clearDateFromInvalidTzOffset = (date: string) => date.replace('Z+0000', 'Z'); // Z+0000 - is invalid format, it's not UTC.

export const addToDate = (unitName: FormatType, amount: number, date?: string): string => dayjs(date).add(amount, unitName).format();

export const subtractFromDate = (unitName: FormatType, amount: number, date?: string): string => dayjs(date).subtract(amount, unitName).format();

export const getUnixTimestamp = (date?: string) => +dayjs(date);

/* for future date hours & mins get removed (by zeroing their values),
 for today HH:mm are set from client's machine time */
export function getTimeWithOrWithoutHoursMins(dateInUtcFormat:string) {
  return isDateInFuture(dateInUtcFormat) ? parseAsISO(dateInUtcFormat.replace(':00Z+0000', '')) : dateToIsoString();
}

export function getWeeksDiff(startDate: number, endDate: number) {
  const msInWeek = 1000 * 60 * 60 * 24 * 7;

  return Math.round(Math.abs(endDate - startDate) / msInWeek);
}

export const addToDateRecurring = (unitName: FormatType, amount: number, recurring: number, date?: Date): Date[] => {
  const recurringDates: Date[] = [];
  for (let index = 0; index < recurring; index++) {
    recurringDates.push(new Date(dayjs(date).add(amount * index, unitName).format()));
  }
  return recurringDates;
};

export const getAge = (date: string) => dayjs().diff(date, 'year');
