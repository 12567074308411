export const IS_USER_LOGGED_IN = 'IS_USER_LOGGED_IN';
export const IS_USER_NOT_LOGGED_IN = 'IS_USER_NOT_LOGGED_IN';
export const USER_LOG_IN_ERROR = 'USER_LOG_IN_ERROR';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const USER_FULL_LOGOUT = 'USER_FULL_LOGOUT';
export const SET_THERAPY_CONSENT_STATE = 'SET_THERAPY_CONSENT_STATE';
export const SET_USER_EDITABLE_DATA = 'SET_USER_EDITABLE_DATA';
export const SET_EMAIL = 'SET_EMAIL';

export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';

export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_PROMO_CODE_DATA = 'SET_PROMO_CODE_DATA';
export const SET_ENTERPRISE = 'SET_ENTERPRISE';

export const SET_TOS_OPTIN = 'SET_TOS_OPTIN';

export const SET_REPEAT_PATIENT = 'SET_REPEAT_PATIENT';
