import * as cookieState from 'utils/cookieState';
import { toTitleCase } from 'utils/index';

import PlushcareWebAPI from 'src/js/utils/api';
import {
  SET_FIRSTNAME,
  SET_LASTNAME,
  SET_DATE,
  SET_INSURANCE_PAYER,
  SET_INSURANCE_MEMBER_ID,
  SET_INSURANCE_PAYER_ID,
  SET_INSURANCE_SUCCESS,
  SET_CLEAR_INSURANCE,
  SET_INSURANCE_ERROR,
  SET_LOADING,
  SET_VALID_INSURANCE_OBJECT,
  SET_CLEAR_INSURANCE_STORE,
  SET_COVERAGE_ID,
  SET_FLAGGED_DATA,
  SET_COVERAGE_TYPE,
  SET_IN_NETWORK,
  SET_EXPLANATION,
  CLEAR_PAYER,
} from 'src/js/actionTypes/InsuranceActionTypes';

// eslint-disable-next-line import/no-cycle
import { setUserEditableData } from 'src/js/actions/UserActions';
import Analytics from 'src/js/utils/analytics/Analytics';

export function setFirstName(firstName) {
  return {
    type: SET_FIRSTNAME,
    value: firstName,
  };
}

export function setLastName(lastName) {
  return {
    type: SET_LASTNAME,
    value: lastName,
  };
}

export function setClearInsurance() {
  return {
    type: SET_CLEAR_INSURANCE,
  };
}

export function setDate(date) {
  return {
    type: SET_DATE,
    value: date,
  };
}

export function setPayerId(payId) {
  return {
    type: SET_INSURANCE_PAYER_ID,
    value: payId,
  };
}

export function setPayerName(payer) {
  return {
    type: SET_INSURANCE_PAYER,
    value: payer,
  };
}

export function setMemberId(memberId) {
  return {
    type: SET_INSURANCE_MEMBER_ID,
    value: memberId,
  };
}

export function setCoverageType(coverageType) {
  return {
    type: SET_COVERAGE_TYPE,
    value: coverageType,
  };
}

export function setInsuranceSuccess(success) {
  return {
    type: SET_INSURANCE_SUCCESS,
    value: success,
  };
}
export function setInsuranceError(error) {
  return {
    type: SET_INSURANCE_ERROR,
    value: error,
  };
}

export function setCoverageID(coverageID) {
  return {
    type: SET_COVERAGE_ID,
    value: coverageID,
  };
}

export function setHasValidInsuranceObject(hasValidInsuranceObject) {
  return {
    type: SET_VALID_INSURANCE_OBJECT,
    value: hasValidInsuranceObject,
  };
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    value: loading,
  };
}

export function setClearInsuranceStore() {
  return {
    type: SET_CLEAR_INSURANCE_STORE,
  };
}

export function setFlaggedData(flagged) {
  return {
    type: SET_FLAGGED_DATA,
    value: flagged,
  };
}

export function setPayer(id, name) {
  return (dispatch) => {
    dispatch(setPayerName(name));
    dispatch(setPayerId(id));
    cookieState.set('payer', { id, name });
  };
}

export function setInNetwork(inNetwork) {
  return {
    type: SET_IN_NETWORK,
    value: inNetwork,
  };
}

export function setExplanation(explanation) {
  return {
    type: SET_EXPLANATION,
    value: explanation,
  };
}

export const clearPayer = (dispatch) => {
  dispatch({
    type: CLEAR_PAYER,
  });
  cookieState.clear('payer');
};

export const setInputInfo = (data) => {
  return (dispatch) => {
    dispatch(setInsuranceError(data.error));
    dispatch(setMemberId(data.memberId));
    dispatch(setPayerId(data.payerId));
    dispatch(setPayerName(data.payer));
  };
};

export const setExistingInsurance = (existingData) => ((dispatch) => {
  dispatch(setFirstName(existingData.first_name));
  dispatch(setLastName(existingData.last_name));
  dispatch(setDate(existingData.date));
  dispatch(setPayer(existingData.payer_id, existingData.payer_name));
  dispatch(setCoverageType(existingData.coverage_type));
  dispatch(setMemberId(existingData.member_id));
  dispatch(setInsuranceError(null));
  dispatch(setHasValidInsuranceObject(true));
});

export const setSuccessfulInsurance = (
  data,
  price,
  saveSuccess,
  coverageID,
  lockCredentials,
  coverageType,
  in_network,
  explanation,
  groupName = null,
) => (
  (dispatch) => {
    dispatch(setFirstName(data.firstName));
    dispatch(setLastName(data.lastName));
    dispatch(setDate(data.date));
    dispatch(setPayer(data.payerId, groupName || data.payer || data.payerName));
    dispatch(setMemberId(data.memberId || data.member_id));
    dispatch(setCoverageID(coverageID));
    dispatch(setCoverageType(coverageType));
    dispatch(setInsuranceError(null));
    dispatch(setInsuranceSuccess(!saveSuccess));
    dispatch(setHasValidInsuranceObject(true));
    dispatch(setInNetwork(in_network));
    dispatch(setFlaggedData({
      flaggedCoverageData: false,
    }));
    dispatch(setExplanation(explanation));
    dispatch(setLoading(false));
  });

export const failInsuranceCheck = (response = null) => ((dispatch) => {
  dispatch(setInsuranceSuccess(false));
  dispatch(setInsuranceError(response));

  dispatch(setLoading(false));
});

export function setInitInsuranceModal() {
  return (dispatch) => {
    dispatch(setInsuranceError(null));
    dispatch(setLoading(false));
  };
}

// TODO:: refactor how this sends formats data more
export const editProfile = (data, ignore) => (async (dispatch) => {
  let {
    first_name: firstName, last_name: lastName, dob,
  } = data.flaggedCoverageData;
  const {
    userResponse, coverageID, payerName, price, in_network, explanation,
  } = data;

  firstName = toTitleCase(firstName);
  lastName = toTitleCase(lastName);

  dob = dob.replace(/-/g, '');
  let sendData = null;
  if (ignore) {
    sendData = {
      coverage_id: coverageID,
      checked_dependant: true,
    };
  } else {
    sendData = {
      first_name: firstName,
      last_name: lastName,
      coverage_id: coverageID,
      checked_dependant: !ignore,
      year: dob.substr(0, 4),
      month: dob.substr(4, 2),
      day: dob.substr(6, 2),
    };
  }
  dispatch(setLoading(true));

  try {
    const { data: userData } = await PlushcareWebAPI.post('patients/users/edit/', sendData, false, true);
    if (userData) {
      const { userprofile: { coverage } } = userData;
      const { coverage_type } = coverage;
      dispatch(
        setSuccessfulInsurance(
          userResponse,
          price,
          true,
          payerName,
          coverageID,
          true,
          coverage_type,
          in_network,
          explanation,
          data.name,
        ),
      );
      if (!ignore) {
        dispatch(
          setUserEditableData(firstName, lastName, dob),
        );
      }
    }
  } catch (error) {
    Analytics.otherException(error);
  } finally {
    dispatch(setLoading(false));
  }
});

export const ignoreSuggestedData = (data) => ((dispatch) => {
  dispatch(editProfile(data, true));
});

export const updateWithSuggestedData = (data) => ((dispatch) => {
  dispatch(editProfile(data, false));
});
