import { HYDRATE } from 'next-redux-wrapper';
import {
  IS_USER_LOGGED_IN,
  IS_USER_NOT_LOGGED_IN,
  SET_EMAIL,
  SET_ENTERPRISE,
  SET_PAYMENT,
  SET_PROMO_CODE_DATA,
  SET_SUBSCRIPTIONS,
  SET_THERAPY_CONSENT_STATE,
  SET_USER_EDITABLE_DATA,
  UPDATE_SUBSCRIPTIONS,
  USER_LOG_IN_ERROR,
  USER_LOG_OUT,
  SET_TOS_OPTIN,
  SET_REPEAT_PATIENT,
} from 'src/js/actionTypes/UserActionType';
import { SET_HOME_ADDRESS } from 'src/js/actionTypes/BookingActionType';
import { UUID } from 'src/js/endpoints/types';
import { PharmacyAddress } from 'src/js/reducers/PharmacyReducer';
import {
  ENTERPRISE_USER_STATUS, Ethnicity, GenderIdentity, Pronouns, Race, SexualOrientation,
} from 'src/js/nextgen/utils/constants';
import { CoverageType } from 'src/js/endpoints/insurance';

export type Gender = 'F' | 'M' | 'U'
export type SubscriptionType = 'mental_health' | 'primary_care' | 'therapy'
export type UserSubscriptionBaseType = 'membership' | 'military' | string
export type SubscriptionInterval = 'day' | 'month' | 'year'

export interface UserSubscription {
  sub_type: SubscriptionType
  account_owner: null | string
  is_active: boolean
  cancelled_at_timestamp: string
  creation_timestamp: string
  base_type: UserSubscriptionBaseType
  recurring_price: number
  interval: SubscriptionInterval
  original_end_timestamp: string // '2021-02-11T10:18:39Z'
  end_date: string // '2021-02-11'
  enrollment_allowed: boolean
  interval_count: number
  subscription_uuid: UUID // '22c4cf8e-e3a1-468f-99ff-d4fbc1a81757'
  original_start_timestamp: string // '2021-01-11T10:18:39Z'
  program_uuid: UUID
  display_name: string // 'Plushcare Therapy Membership'
  effective_end_timestamp: string// '2021-02-11T10:18:39Z'
  start_date: string // '2021-01-11'
  has_rx_free_appointment?: boolean,
  secondary_members_allowed: number,
  is_in_trial_period: boolean,
}

export interface Child {
  first_name: string
  last_name: string
  preferred_name: string | null
  weight: number
  gender: Gender
  height: number
  date_of_birth: string // '2010-01-01'
  user: {
    phone_number: string // '1234567890'
    user_id: UUID // 'cedae0fe-9cd9-4863-90d5-9331985c7ff3'
  }
  id: number
}

export interface UserAddress {
  street_address_two: string | null
  state: {
    video_only: boolean
    name: string // 'California'
    abbreviation: string // 'CA'
    active: boolean
    identifier: string // 'America/Los_Angeles'
    id: number // maybe not used
  }
  zip_code: string // '94109'
  street_address: string // '1252 Pacific Ave'
  city: string // 'San Francisco'
}

export interface Employee {
  employer_group_id: string,
  is_bsc_ifp: boolean,
  is_hmo: boolean,
  risk_assessment_delay: number | null,
  risk_assessment_first: boolean,
  specialty_care_enabled: boolean,
  billing_method: string | null
}

export interface UserData {
  user_id: UUID // 'cedae0fe-9cd9-4863-90d5-9331985c7ff3'
  email: string,
  employee: Employee | null,
  enterprise: string,
  enterprise_label: string,
  first_name: string,
  id: number,
  impersonator: string,
  is_admin: boolean,
  is_enterprise_user: boolean,
  is_logged_in: boolean,
  last_name: string,
  offers: {
    therapy_membership: {
      recurring_price: number
      interval: 'month' | 'year'
      subscription_uuid: UUID // '22c4cf8e-e3a1-468f-99ff-d4fbc1a81757'
      discount_amount: number | null
    }
  },
  password_origin: 'user' | 'partner',
  phone_number: string,
  phonenumber_origin: 'user' | 'User' | 'partner',
  segment_event_name: string,
  source: {
    source_id: string
    external_id: string | null
    isFitbitStudy: boolean,
    is_healthplan: boolean,
    link_title: string
    link_description: string
    link_url: string
  } | null,
  subscription_memberships: Array<UserSubscription>,
  type: 'User',
  userprofile: UserProfile,
  accolade_support_phone_number: string,
}

export interface UserProfile {
  address: UserAddress | null,
  allow_therapy_recurring_appt: boolean,
  balance: number,
  biological_sex?: Gender,
  cancellation_policy_exempt: boolean,
  cc_last_four: string // '4242'
  cc_brand: string // 'Visa'
  child: Array<Child> | null
  coverage: [] | [{
    first_name: string,
    last_name: string,
    date: string,
    price: number,
    coverage_type: CoverageType,
    payer_name: string,
    payer_id: string,
    member_id: string,
    is_expired?: boolean,
  }]
  date_joined: string,
  date_of_birth: string,
  ethnicity?: Ethnicity,
  gender: string,
  group: unknown,
  identified_gender?: GenderIdentity,
  is_in_therapy_disabled_tpa: boolean,
  is_informed_consent: boolean
  is_sso_user: false,
  repeat_patient: boolean,
  legal_first_name?: string,
  legal_last_name?: string,
  num_invoice_errors: number,
  preferred_name?: string,
  primary_care_provider?: number,
  promo_code: Array<{
    activation_key: string // 'NoCovSYG4MJ'
    amount: {
      give_amount: number // used in ReferralCodeWidget
    }
    shareable_link: string // 'https://stage.plush.care/NoCovSYG4MJ' // used in ReferralFeedback
    id: number // 48526
  }> | [],
  pronouns?: Pronouns,
  sexual_orientation?: SexualOrientation,
  race?: Race,
  ss_pharmacy?: PharmacyAddress | null,
  stripe_customer_id: string // 'cus_Iim9dSikERqNIA'
}

export const defaultUserData: UserData = {
  user_id: '',
  email: '',
  employee: null,
  enterprise: ENTERPRISE_USER_STATUS.NONE,
  enterprise_label: '',
  first_name: '',
  last_name: '',
  impersonator: '',
  id: 0,
  is_admin: false,
  is_enterprise_user: false,
  is_logged_in: false,
  offers: {
    therapy_membership: {
      discount_amount: 0,
      interval: 'year',
      recurring_price: 0,
      subscription_uuid: '',
    },
  },
  password_origin: 'user',
  phone_number: '',
  phonenumber_origin: 'user',
  segment_event_name: '',
  source: null,
  subscription_memberships: [],
  type: 'User',
  userprofile: {
    address: null,
    allow_therapy_recurring_appt: false,
    balance: 0,
    cancellation_policy_exempt: false,
    cc_brand: '',
    cc_last_four: '',
    child: null,
    coverage: [],
    date_joined: '',
    date_of_birth: '',
    gender: 'F',
    group: null,
    is_in_therapy_disabled_tpa: false,
    is_informed_consent: false,
    is_sso_user: false,
    num_invoice_errors: 0,
    preferred_name: '',
    promo_code: [],
    repeat_patient: false,
    ss_pharmacy: null,
    stripe_customer_id: '',
  },
  accolade_support_phone_number: '',
};

// TODO looks like we need to align this object to UserState type
export const initialState = {
  id: 0,
  userid: '',
  type: 'User',
  fullName: '',
  firstName: '',
  lastName: '',
  impersonator: '',
  isLoggedIn: false,
  error: false,
  errorMessage: '',
  promoCodeMetaData: {},
  subscriptions: [],
  payment: {
    cardLastFour: null,
    cardBrand: null,
    stripeToken: null,
    numInvoiceErrors: null,
  },
  insurance: false,
  enterprise: ENTERPRISE_USER_STATUS.NONE,
  had_paid_therapy_apt: false,
  tosOptin: false,
  userFeatureFlags: {
    is_specialty_care_enabled: false,
  },
  userprofile: {
    address: null,
    balance: 0.0,
    cancellation_policy_exempt: false,
    child: null,
    coverage: [],
    date_of_birth: '',
    is_informed_consent: false,
    is_in_therapy_disabled_tpa: false,
    preferred_name: '',
    promo_code: [],
    repeat_patient: false,
  },
};

// param order matters here, putting state after action breaks the app
// eslint-disable-next-line default-param-last
export default function userReducer(state = initialState, action: Actions) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return { ...state, ...(payload.user as UserData) };
    case IS_USER_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true,
        ...payload,
        error: false,
        errorMessage: '',
      };
    case SET_USER_EDITABLE_DATA: {
      const { firstName, lastName, dateOfBirth } = payload;
      return {
        ...state,
        firstName,
        lastName,
        dateOfBirth,
      };
    }
    case USER_LOG_IN_ERROR: {
      const { errorMessage = '' } = payload;
      return {
        ...state,
        isLoggedIn: false,
        error: true,
        errorMessage,
      };
    }
    case IS_USER_NOT_LOGGED_IN:
    case USER_LOG_OUT:
      return {
        ...initialState,
      };
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: payload,
      };
    case UPDATE_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: state.subscriptions.map((subscription: UserSubscription) => (
          payload.subscription_uuid === subscription.subscription_uuid ? payload : subscription
        )),
      };
    case SET_HOME_ADDRESS:
      return {
        ...state,
        address: action.homeAddress,
      };
    case SET_PAYMENT:
      return {
        ...state,
        payment: { ...payload },
      };
    case SET_PROMO_CODE_DATA:
      return {
        ...state,
        promoCodeMetaData: payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: payload,
      };
    case SET_THERAPY_CONSENT_STATE:
      return {
        ...state,
        isInformedConsent: payload,
      };
    case SET_ENTERPRISE:
      return {
        ...state,
        enterprise: action.enterprise,
      };
    case SET_TOS_OPTIN:
      return {
        ...state,
        tosOptin: payload,
      };
    case SET_REPEAT_PATIENT:
      return {
        ...state,
        userprofile: {
          ...(payload.userprofile as UserProfile),
          repeat_patient: payload.repeat_patient,
        },
        tosOptin: payload,
      };
    default:
      return state;
  }
}

export type UserFeatureFlags = {
  is_specialty_care_enabled: boolean,
}

export type UserState = Omit<UserData, 'coverage'> & {
  id: number,
  userid: string,
  type: 'User',
  fullName: string,
  firstName: string,
  lastName: string,
  impersonator: string,
  isLoggedIn: boolean,
  error: boolean,
  errorMessage: string,
  promoCodeMetaData: unknown,
  subscriptions: Array<UserSubscription>,
  payment: {
    cardLastFour: string | null,
    cardBrand: string | null,
    stripeToken: string | null,
    numInvoiceErrors: unknown,
  },
  insurance: boolean,
  coverage: [{
    payerID: string,
    payerName: string,
    memberID: string,
  }] | []
  phoneNumberOrigin: 'user' | 'User' | 'partner'
  tosOptin: false,
  userFeatureFlags: UserFeatureFlags,
  userprofile: UserProfile
}

type Actions = {
  type: string;
  payload: { [k: string]: unknown }
  homeAddress: UserAddress
} & {
  [k in keyof Omit<UserState, 'type'>]: UserState[k]
}
