import { GetServerSidePropsContext } from 'next';
import cookies from 'next-cookies';
import Cookies from 'js-cookie';
import { TherapyFlowType } from 'src/js/components/bookingFlow/Therapy/helpers';

/**
 * Utility which define state layer common for browser and node ssr server
 * Look on that as another state layer working on cookies
 * Allows also for saving objects in cookies
 */

type ServerSideState = {
  payer?: {
    id: string,
    name: string
  },
  location_state?: string,
  geolocation_state?: string,
  date?: string,
  user_timezone?: string,
  user_id: string,
  therapy: TherapyFlowType,
}

const decodeValue = (value: string | undefined): string | object | undefined => {
  if (value && value.includes('obj:')) {
    return JSON.parse(value.slice('obj:'.length));
  }
  return value;
};

const encodeValue = (value: string | object) => {
  return typeof value === 'object' ? `obj:${JSON.stringify(value)}` : value;
};

export const set = <K extends keyof ServerSideState>(label: K, value: Required<ServerSideState[K]>): void => {
  Cookies.set(label, encodeValue(value));
};

export const clear = <K extends keyof ServerSideState>(label: K): void => {
  Cookies.remove(label);
};

export const get = <K extends keyof ServerSideState>(label: K) => {
  return decodeValue(Cookies.get(label)) as ServerSideState[K] | undefined;
};

export const getInServer = <K extends keyof ServerSideState>(
  ctx: GetServerSidePropsContext,
  label: K) => decodeValue(cookies(ctx)[label]) as ServerSideState[K] | undefined;
